const Price = ({ currency, prices, duration, AdobePrices }) => {
    return (
      <>
        <h1 style={{ textWrap: "nowrap", width: "fit-content" }}>
          <span style={{ fontSize: 24 }}>{currency}</span> {prices[duration]}.00{" "}
          {duration !== 30 && (
            <span style={{ fontSize: 14, fontWeight: 400 }}>
              (= {currency}{" "}
              {duration === 30
                ? 14
                : duration === 365
                ? 12
                : duration === 730
                ? 10
                : duration === 1095
                ? 9
                : "???"}
              .00 /month)
            </span>
          )}
        </h1>
        <p style={{ color: "red" }}>
          You save{" "}
          <b style={{ color: "red" }}>
            ≈ {currency} {(AdobePrices[duration] - prices[duration]).toFixed(2)}
          </b>{" "}
          compared to Adobe.com prices.
        </p>
      </>
    );
  };
  
  export default Price;