import { Elements } from "@stripe/react-stripe-js";
import Footer from "../../Footer/Footer";
import Wrapper from "../../Wrapper/Wrapper";

const Mobile = ({ mobileMain, isHovered, getSvgPosition, handleSubmit, clientSecret, formSubmittedAutoPay, name, setName, duration, setDuration, email, setEmail, isAutoPay, setIsAutoPay, svgRef, handleMouseEnter, handleMouseLeave, currency, prices, AdobePrices, isLoading, isPaymentDone, stripePromise, options, priceData, setIsPaymentDone, returnToHome}) => {
    return <div className='mobile-main'>
    <div className='mobile-main-main' ref={mobileMain}>
      {isHovered === true && (<div className='question-div' style={{ position: 'fixed', ...getSvgPosition(), zIndex: 999, width: '230px', backgroundColor: 'black', color: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>If disabled, your subscription will be revoked at the end of the first period. If enabled, renewal will be automatic.<br></br>To cancel auto-pay, simply contact us.</div>)}
      <form onSubmit={handleSubmit} className={`inputs-mobile ${!clientSecret && formSubmittedAutoPay === false ? "shown" : ''}`}>
        {!clientSecret && formSubmittedAutoPay === false && <div className='inputs-subdiv-mobile'>
          <h1 style={{ marginBottom: 0, textAlign: 'left' }}>Create an order</h1>
          <p style={{ fontSize: 18, margin: 0, marginTop: 10 }}>Apply the Creative Cloud All Apps plan to your own Adobe account.</p>
          <div className='input-container-mobile'>
            <div className='input-div-mobile'>
              <label style={{ textAlign: 'left', fontSize: 18 }}>Full name:</label>
              <div className='input-wrapper-mobile'><input style={{ width: '-webkit-fill-available', fontSize: 18 }} value={name} spellCheck={false} required onChange={(e) => (setName(e.target.value))} placeholder='John Smith' type='name' /></div>
            </div>
            <div className='input-div-mobile'>
              <label style={{ textAlign: 'left', fontSize: 18 }}>Adobe account email address:</label>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                <div className='input-wrapper-mobile'><input style={{ width: '-webkit-fill-available', fontSize: 18 }} value={email} spellCheck={false} required onChange={(e) => (setEmail(e.target.value))} placeholder='john.smith@gmail.com' type='email' /></div>
              </div>
            </div>
            <div className='input-div-mobile'>
              <label style={{ textAlign: 'left', fontSize: 18 }}>Duration of your subscription:</label>
              <select style={{ fontSize: 18, backgroundColor: 'white' }} value={duration} onChange={(e) => (setDuration(parseFloat(e.target.value)))}>
                <option disabled>Want a 3-days free trial? Contact us.</option>
                <option value={30}>1 month</option>
                <option value={365}>1 year</option>
                <option value={730}>2 years</option>
                <option value={1095}>3 years</option>
              </select>
            </div>
            <div className='input-div-mobile'>
              <label style={{ textAlign: 'left', display: 'flex', alignItems: 'center', gap: 10, fontSize: 18 }}>Enable auto-pay:<svg ref={svgRef} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} style={{ cursor: 'pointer' }} fill="black" height="20" viewBox="0 0 15 15" width="20" xmlns="http://www.w3.org/2000/svg"><g fill="#black"><path d="m6.74984 10.5c0 .4142.33579.75.75.75.41422 0 .75-.3358.75-.75s-.33578-.74999-.75-.74999c-.41421 0-.75.33579-.75.74999z" /><path d="m8.94995 6.25001c0-.67789-.58507-1.325-1.45-1.325-.86492 0-1.45.64711-1.45 1.325 0 .30376-.24624.55-.55.55s-.55-.24624-.55-.55c0-1.32211 1.11493-2.425 2.55-2.425 1.43508 0 2.55005 1.10289 2.55005 2.425 0 1.10382-.73004 1.64038-1.26449 1.96506-.10264.06235-.19141.1138-.26993.1593-.15058.08727-.26359.15276-.36509.23184-.06564.05115-.09133.08341-.10001.0971l-.00054.00087c-.00224.30184-.24762.54583-.54999.54583-.30375 0-.55-.24624-.55-.55 0-.46802.28211-.77268.52446-.96151.16338-.1273.38078-.25355.56398-.35994.0641-.03723.12404-.07204.176-.10361.46555-.28282.73556-.53376.73556-1.02494z" /><path clipRule="evenodd" d="m14 7.5c0 3.5899-2.9101 6.5-6.5 6.5-3.58985 0-6.5-2.9101-6.5-6.5 0-3.58985 2.91015-6.5 6.5-6.5 3.5899 0 6.5 2.91015 6.5 6.5zm-1 0c0 3.0376-2.4624 5.5-5.5 5.5-3.03757 0-5.5-2.4624-5.5-5.5 0-3.03757 2.46243-5.5 5.5-5.5 3.0376 0 5.5 2.46243 5.5 5.5z" fillRule="evenodd" /></g></svg></label>
              <select style={{ fontSize: 18, backgroundColor: 'white' }} value={isAutoPay} onChange={(e) => (setIsAutoPay(e.target.value === 'true' || e.target.value === true))}>
                <option value={true}>Yes (free)</option>
                <option value={false}>No, thanks</option>
              </select>
            </div>
          </div>
          <div>
            <h1 style={{ textWrap: 'nowrap', width: 'fit-content' }}><span style={{ fontSize: 28 }}>{currency}</span> {prices[duration]}.00 {duration !== 30 && <span style={{ fontSize: 14, fontWeight: 400 }}>(= {currency} {duration === 30 ? 14 : (duration === 365 ? 12 : (duration === 730 ? 10 : (duration === 1095 ? 9 : '???')))}.00 /month)</span>}</h1>
            <p style={{ color: 'black', fontSize: 18 }}>You save <b>{currency}{(AdobePrices[duration] - prices[duration]).toFixed(2)}</b> compared to adobe.com prices.</p>
          </div>
          <div className='pay-button-div-mobile'>
            <button className='button' disabled={(!email || !name)} type="submit"><span id="button-text">
              {!clientSecret && formSubmittedAutoPay === false && isLoading === true ? <div className="spinner" id="spinner"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" width={25} height={25} style={{ shapeRendering: 'auto', display: 'block', background: 'transparent', width: '21.5', height: '21.5', paddingLeft: 11, paddingRight: 11, paddingTop: -2, paddingBottom: -2 }}><g><circle strokeDasharray="136.659280431156 47.553093477052" r="29" strokeWidth="10" stroke="#ffffff" fill="none" cy="50" cx="50"><animateTransform keyTimes="0;1" values="0 50 50;360 50 50" dur="1.3513513513513513s" repeatCount="indefinite" type="rotate" attributeName="transform"></animateTransform></circle><g></g></g></svg></div> : "Select"}</span></button>
          </div>
        </div>}
      </form>
      <div className={`payment-container-mobile ${(isPaymentDone === false && clientSecret) || (formSubmittedAutoPay === true && isPaymentDone === false) ? "shown" : ''}`}>
        {(isPaymentDone === false && clientSecret && isAutoPay === false) && <Elements stripe={stripePromise} options={options}>
          <button onClick={returnToHome} className='return-button'><span style={{ transform: 'translateY(-1px)', fontSize: 16 }}>←</span>Return to home</button>
          <Wrapper mobileMain={mobileMain.current.getBoundingClientRect().top + document.documentElement.scrollTop} currency={currency} priceId={priceData[duration]} autoPay={isAutoPay} name={name} email={email} duration={duration} prices={prices} isPaymentDone={isPaymentDone} setIsPaymentDone={setIsPaymentDone} clientSecret={clientSecret} bulk={false} bulk_data={null} />
        </Elements>}
        {(isPaymentDone === false && isAutoPay === true && formSubmittedAutoPay === true) && <Elements stripe={stripePromise} options={options}>
          <button onClick={returnToHome} className='return-button'><span style={{ transform: 'translateY(-1px)', fontSize: 16 }}>←</span>Return to home</button>
          <Wrapper mobileMain={mobileMain.current.getBoundingClientRect().top + document.documentElement.scrollTop} currency={currency} priceId={priceData[duration]} autoPay={isAutoPay} name={name} email={email} duration={duration} prices={prices} isPaymentDone={isPaymentDone} setIsPaymentDone={setIsPaymentDone} bulk={false} bulk_data={null} />
        </Elements>}
      </div>
      <div className={`payment-container-done-mobile ${isPaymentDone === true && isLoading === false ? "shown" : ''}`}><div className='success-div'><div class="animation-ctn">
        <div style={{ display:'flex', width:'100%', flexDirection:'column' }}>
          {isPaymentDone && !isLoading && <img src='/images/tick.svg' alt='tick' style={{ height:80, alignSelf:'center' }} />}
        </div>
      </div>{isPaymentDone === true && isLoading === false && <h2 style={{ textAlign: 'center', marginTop: 60 }}>Payment successful.</h2>}{isPaymentDone === true && isLoading === false && <p style={{ textAlign: 'center', marginTop: 60 }}>You should receive your invitation email within 24 hours.</p>}{isPaymentDone === true && isLoading === false && <p style={{ textAlign: 'center', marginTop: 10 }}>If any problem, contact us by email:</p>}{isPaymentDone === true && isLoading === false && <h3 style={{ textAlign: 'center', marginTop: 20 }}>help@cheapcc.net</h3>}</div></div>
    </div>

    <div className='faq-mobile'>
      <h1>FAQ (read!)</h1>
      <h3 className='faq-mobile-title'>What is CheapCC?</h3>
      <p>CheapCC.net is a site where you can legally get the genuine Adobe CC All Apps plan applied to your own Adobe account, at a much lower price.</p>
      <h3 className='faq-mobile-title'>What is included?</h3>
      <p>Here's the full list of what is included: all Adobe applications (except Substance 3D), 80GB cloud storage, Firefly AI with 250 credits renewed every month, access to beta apps, and 2 active simultaneous desktop sessions + with 2 mobile sessions. It's the authentic and official subscription, no need to use a VPN.</p>
      <h3 className='faq-mobile-title'>Where does the subscription come from?</h3>
      <p>The subscription is not hacked nor stolen. We have a partnership with many schools around the world that have access to unused Adobe subscriptions and are happy to help people in need.</p>
      <p>Your Adobe subscription is applied to your own Adobe account via an e-mail invitation from one of these schools.</p>
      <h3 className='faq-mobile-title'>Why can't I place an order?</h3>
      <p>Because of the nature of these subscriptions, the number of places available is not infinite, although we do our best to offer as many as possible at the most affordable price.</p>
      <p>If you can place an order, there are still places available. If you can't place an order, don't worry: new places will be available soon.</p>
      <h3 className='faq-mobile-title'>How can I be sure that my 3-year subscription will really last 3 years?</h3>
      <p>We have many partner schools that are certified by Adobe. What's more, it's only an external structure not managed by CheapCC that is in contact with these schools.</p>
      <p>This means that even if CheapCC were to disappear, subscriptions purchased on CheapCC would continue to be active.</p>
      <h3 className='faq-mobile-title' style={{ marginTop: 30 }}>Does anyone has access to my content?</h3>
      <p>We DO NOT have access to your content created in the Adobe applications. Only Adobe has potential access, particularly when using online features, such as generative AI. Here's an article about the recent updates to Adobe's terms of use on their access to user content:</p>
      <a style={{ color: '#596b76', maxWidth:'-webkit-fill-available' }} target='_blank' rel="noreferrer" href='https://blog.adobe.com/en/publish/2024/06/06/clarification-adobe-terms-of-use'>blog.adobe.com/en/publish/2024/06/06/clarification-adobe-terms-of-use</a>
    </div>
    <Footer style={{ width: 'calc(100% - 100px)', marginTop: 20, marginBottom: 8 }} mobile={true} />
  </div>
}

export default Mobile;