import React from "react"
import { Link } from "react-router-dom"

const Footer = ({style, mobile}) => {
    return (
        <div style={{...style, alignSelf:'center' }} className='footer'>
          <div className='footer-inside'>
            <Link style={{ color:'black', outline:'none', textDecoration:'none', fontSize:mobile===true?'18px':'' }} to='privacy-notice'>Privacy notice</Link>
          </div>
          <div className='footer-inside'>
            <Link style={{ color:'black', outline:'none', textDecoration:'none', fontSize:mobile===true?'18px':'' }} to='/refund-policy'>Refund policy</Link>
          </div>
          <div className='footer-inside'>
            <Link style={{ color:'black', outline:'none', textDecoration:'none', fontSize:mobile===true?'18px':'' }} to='/terms-of-service'>Terms of service</Link>
          </div>
        </div>
    )
}
export default Footer
