import '../../css/App.css'
import { loadStripe } from '@stripe/stripe-js';
import React, { useState, useRef, useEffect } from 'react';
import { useMediaQuery } from '@react-hook/media-query';
import Desktop from './Desktop/Desktop'
import Mobile from './Mobile/Mobile'
import Logo from '../../images/cheapcclogo.avif'

const stripePromise = loadStripe('pk_live_51Q0mpPAvc7OJE1HgrXR7hUfqHQT6vYzKOFhUyY5SOnGmCWzqyDNCNL4ZazSdmLlW2ypzidtPFySZ1P0CiLbCx4aK002N51ezBu');

function Main() {
  const [faqShown, setFaqShown] = useState(false)
  const [currency, setCurrency] = useState('€')
  const [prices] = useState({ 30: 14, 365: 144, 730: 240, 1095: 320 });
  const [AdobePrices] = useState({ 30: 59.99, 365: 659.88, 730: 659.88 * 2, 1095: 659.88 * 3 });
  const [name, setName] = useState('');
  const [isAutoPay, setIsAutoPay] = useState(false);
  const [email, setEmail] = useState(null);
  const [priceData, setpriceData] = useState({
    30: currency === '€' ? 'price_1Q0nKkAvc7OJE1HgnItk70ko' : 'price_1Q1ojBAvc7OJE1Hg5OQoXyDD',
    365: currency === '€' ? 'price_1Q0nLIAvc7OJE1HgEv3rHARq' : 'price_1Q1oiuAvc7OJE1HgnEXnNZqw',
    730: currency === '€' ? 'price_1Q0nMSAvc7OJE1HgtkmQqjAN' : 'price_1Q1oiPAvc7OJE1HgaFePnPAm',
    1095: currency === '€' ? 'price_1Q0nN2Avc7OJE1HgYFajWIaO' : 'price_1Q1ohsAvc7OJE1Hg9A9ltmAz',
  })
  const [duration, setDuration] = useState(30);
  const [isLoading, setIsLoading] = useState(false)
  const [isPaymentDone, setIsPaymentDone] = useState(false);
  const [clientSecret, setClientSecret] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [formSubmittedAutoPay, setFormSubmittedAutoPay] = useState(false);
  const isMobile = useMediaQuery('(max-width: 668px)');

    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await fetch('https://extreme-ip-lookup.com/json/?key=uxJZ5TPQRYbhGqTVc6DI');
          const data = await response.json();
          const country = data.countryCode;
          setCurrency(country === 'US' ? '$' : '€');
          setpriceData({
            30: country === 'US' ? 'price_1Q1ojBAvc7OJE1Hg5OQoXyDD' : 'price_1Q0nKkAvc7OJE1HgnItk70ko',
            365: country === 'US' ? 'price_1Q1oiuAvc7OJE1HgnEXnNZqw' : 'price_1Q0nLIAvc7OJE1HgEv3rHARq',
            730: country === 'US' ? 'price_1Q1oiPAvc7OJE1HgaFePnPAm' : 'price_1Q0nMSAvc7OJE1HgtkmQqjAN',
            1095: country === 'US' ? 'price_1Q1ohsAvc7OJE1Hg9A9ltmAz' : 'price_1Q0nN2Avc7OJE1HgYFajWIaO',
          });
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
  
      fetchData();
  }, [currency]);

  useEffect(() => {
    if ((isMobile === true || isMobile === 'true') && (isPaymentDone===true || isPaymentDone==='true')) {
      const { top } = mobileMain.current.getBoundingClientRect();
      window.scrollTo({ top: top + document.documentElement.scrollTop - 15, behavior: 'smooth' });
    }
  }, [isPaymentDone, isMobile]);

  const svgRef = useRef(null);
  const mobileMain = useRef(null);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const returnToHome = () => {
    if (isAutoPay === true || isAutoPay === 'true') {
      setFormSubmittedAutoPay(false);
    }
    if (isAutoPay === false || isAutoPay === 'false') {
      setClientSecret(null);
    }
    if (isMobile === true || isMobile === 'true') {
      const { top } = mobileMain.current.getBoundingClientRect();
      window.scrollTo({ top: top + document.documentElement.scrollTop - 15, behavior: 'smooth' });
    }
  }

  const getSvgPosition = () => {
    if (svgRef.current) {
      const { x, y, width } = svgRef.current.getBoundingClientRect();
      return { top: y - 20, left: isMobile ? (x - width - 50) : (x - width + 50) };
    }
    return { top: 0, left: 0 };
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    try {

      const agent = navigator.userAgent;

      if (isAutoPay === false || isAutoPay === 'false') {
        await fetch('/.netlify/functions/create-payment-intent', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            browserAgent: agent,
            amount: prices[duration],
            name: name,
            email: email,
            duration: duration,
            currency: currency,
            bulk: false,
            orders: null,
          }),
        })
          .then((res) => res.json())
          .then((data) => {
            setClientSecret(data.clientSecret);
          });
      }
      if (isAutoPay === true || isAutoPay === 'true') {
        setFormSubmittedAutoPay(true)
      }

    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const appearance = {
    theme: 'stripe',
    variables: {
      colorPrimary: '#1c53de'
    }
  };

  const options = isAutoPay === false ? { clientSecret, appearance, business: "ASM" } : { mode: 'setup', currency: currency === '$' ? 'usd' : 'eur', appearance, business: "ASM" }

  return (
    <div className="App">
      <div className='logo-div'>
        <img style={{ display: 'flex', width: '100%', justifyContent: 'center' }} draggable={false} src={Logo} className='logo' alt='cheapcc-logo' />
      </div>
      {(isMobile === 'false' || isMobile === false) 
      && <Desktop isHovered={isHovered} getSvgPosition={getSvgPosition} handleSubmit={handleSubmit} clientSecret={clientSecret} formSubmittedAutoPay={formSubmittedAutoPay} name={name} setName={setName} duration={duration} setDuration={setDuration} email={email} setEmail={setEmail} isAutoPay={isAutoPay} setIsAutoPay={setIsAutoPay} svgRef={svgRef} handleMouseEnter={handleMouseEnter} handleMouseLeave={handleMouseLeave} currency={currency} prices={prices} AdobePrices={AdobePrices} isLoading={isLoading} isPaymentDone={isPaymentDone} stripePromise={stripePromise} options={options} setClientSecret={setClientSecret} priceData={priceData} setIsPaymentDone={setIsPaymentDone} setFormSubmittedAutoPay={setFormSubmittedAutoPay} faqShown={faqShown} setFaqShown={setFaqShown} /> 
      }
      {(isMobile === 'true' || isMobile === true) 
      && <Mobile mobileMain={mobileMain} isHovered={isHovered} getSvgPosition={getSvgPosition} handleSubmit={handleSubmit} clientSecret={clientSecret} formSubmittedAutoPay={formSubmittedAutoPay} name={name} setName={setName} duration={duration} setDuration={setDuration} email={email} setEmail={setEmail} isAutoPay={isAutoPay} setIsAutoPay={setIsAutoPay} svgRef={svgRef} handleMouseEnter={handleMouseEnter} handleMouseLeave={handleMouseLeave} currency={currency} prices={prices} AdobePrices={AdobePrices} isLoading={isLoading} isPaymentDone={isPaymentDone} stripePromise={stripePromise} options={options} priceData={priceData} setIsPaymentDone={setIsPaymentDone} returnToHome={returnToHome} /> 
      }
    </div>
  );
}

export default Main;
