const AutoPaySvg = ({ svgRef, handleMouseEnter, handleMouseLeave }) => {
    return (
      <>
        <svg
          ref={svgRef}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          style={{ cursor: "pointer" }}
          fill='black'
          height='20'
          viewBox='0 0 15 15'
          width='20'
          xmlns='http://www.w3.org/2000/svg'
        >
          <g fill='#black'>
            <path d='m6.74984 10.5c0 .4142.33579.75.75.75.41422 0 .75-.3358.75-.75s-.33578-.74999-.75-.74999c-.41421 0-.75.33579-.75.74999z' />
            <path d='m8.94995 6.25001c0-.67789-.58507-1.325-1.45-1.325-.86492 0-1.45.64711-1.45 1.325 0 .30376-.24624.55-.55.55s-.55-.24624-.55-.55c0-1.32211 1.11493-2.425 2.55-2.425 1.43508 0 2.55005 1.10289 2.55005 2.425 0 1.10382-.73004 1.64038-1.26449 1.96506-.10264.06235-.19141.1138-.26993.1593-.15058.08727-.26359.15276-.36509.23184-.06564.05115-.09133.08341-.10001.0971l-.00054.00087c-.00224.30184-.24762.54583-.54999.54583-.30375 0-.55-.24624-.55-.55 0-.46802.28211-.77268.52446-.96151.16338-.1273.38078-.25355.56398-.35994.0641-.03723.12404-.07204.176-.10361.46555-.28282.73556-.53376.73556-1.02494z' />
            <path
              clipRule='evenodd'
              d='m14 7.5c0 3.5899-2.9101 6.5-6.5 6.5-3.58985 0-6.5-2.9101-6.5-6.5 0-3.58985 2.91015-6.5 6.5-6.5 3.5899 0 6.5 2.91015 6.5 6.5zm-1 0c0 3.0376-2.4624 5.5-5.5 5.5-3.03757 0-5.5-2.4624-5.5-5.5 0-3.03757 2.46243-5.5 5.5-5.5 3.0376 0 5.5 2.46243 5.5 5.5z'
              fillRule='evenodd'
            />
          </g>
        </svg>
      </>
    );
  };
  
  export default AutoPaySvg;