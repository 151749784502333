const EmailInput = ({ email, setEmail }) => {
    return (
      <>
        <div className='input-div'>
          <label style={{ textAlign: "left", gap: 10 }}>
            Adobe account email address:
          </label>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <div
              className='input-wrapper'
              style={{ maxWidth: "calc(100% - 40px)", minWidth: 280 }}
            >
              <input
                style={{ width: "100%", borderRadius: 5 }}
                value={email}
                spellCheck={false}
                required
                onChange={(e) => setEmail(e.target.value)}
                placeholder='john.smith@gmail.com'
                type='email'
              />
            </div>
          </div>
        </div>
      </>
    );
  };
  
  export default EmailInput;