const Faq = ({ faqShown, setFaqShown }) => {
    return <>
    <div className={`faq ${faqShown ? 'shown' : ''}`}>
        <div onClick={() => (setFaqShown(!faqShown))} style={{ cursor: 'pointer', display: 'flex', flexDirection: 'row', width: '100%', alignItems: faqShown ? 'flex-start' : 'center', justifyContent: 'space-between' }}>
          <h2 style={{ fontSize:40, fontWeight:300 }} className={`faq-title ${faqShown ? 'withMargin' : ''}`}>FAQ (read!)</h2>
          <svg style={{ transition: '0.1s', transform: faqShown ? 'rotate(90deg)' : '' }} fill="#000000" height="30px" width="30px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 330 330">
            <path id="XMLID_222_" d="M250.606,154.389l-150-149.996c-5.857-5.858-15.355-5.858-21.213,0.001
            c-5.857,5.858-5.857,15.355,0.001,21.213l139.393,139.39L79.393,304.394c-5.857,5.858-5.857,15.355,0.001,21.213
            C82.322,328.536,86.161,330,90,330s7.678-1.464,10.607-4.394l149.999-150.004c2.814-2.813,4.394-6.628,4.394-10.606
            C255,161.018,253.42,157.202,250.606,154.389z"/>
          </svg>
        </div>
        <div className={`faq-text ${faqShown ? 'shown' : ''}`}>
          <h3 style={{ textAlign: 'left' }}>{faqShown ? "What is CheapCC.net?" : ""}</h3>
          <p>{faqShown ? "CheapCC.net is a site where you can legally get the genuine Adobe CC All Apps plan applied to your own Adobe account, at a much lower price." : ""}</p>
          <h3 style={{ textAlign: 'left', marginTop: 20 }}>{faqShown ? "Where does the subscription come from?" : ''}</h3>
          <p style={{ marginBottom: 5 }}>{faqShown ? "The subscription is not hacked nor stolen. We have a partnership with many schools around the world that have access to unused Adobe subscriptions and are happy to help people in need." : ""}</p>
          <p style={{ marginBottom: 5 }}>{faqShown ? "Your Adobe subscription is applied to your own Adobe account via an e-mail invitation from one of these schools." : ""}</p>
          <h3 style={{ textAlign: 'left', marginTop: 20 }}>{faqShown ? "Why can't I place an order?" : ''}</h3>
          <p style={{ marginBottom: 5 }}>{faqShown ? "Because of the nature of these subscriptions, the number of places available is not infinite, although we do our best to offer as many as possible at the most affordable price." : ""}</p>
          <p style={{ marginBottom: 5 }}>{faqShown ? "If you can place an order, there are still places available. If you can't place an order, don't worry: new places will be available soon." : ""}</p>
          <h3 style={{ textAlign: 'left', marginTop: 20 }}>{faqShown ? "How can I be sure that my 3-year subscription will really last 3 years?" : ''}</h3>
          <p style={{ marginBottom: 5 }}>{faqShown ? "We have many partner schools that are certified by Adobe. What's more, it's only an external structure not managed by CheapCC that is in contact with these schools." : ""}</p>
          <p style={{ marginBottom: 5 }}>{faqShown ? "This means that even if CheapCC were to disappear, subscriptions purchased on CheapCC would continue to be active." : ""}</p>
          <h3 style={{ textAlign: 'left', marginTop: 20 }}>{faqShown ? "Does anyone has access to my content?" : ""}</h3>
          <p>{faqShown ? "We DO NOT have access to your content created in the Adobe applications. Only Adobe has potential access, particularly when using online features, such as generative AI. Here's an article about the recent updates to Adobe's terms of use on their access to user content:" : ""}</p>
          {faqShown && <a style={{ textAlign: 'left', display: 'flex', marginLeft: 10, width: '100%', color: '#596B76' }} href='https://blog.adobe.com/en/publish/2024/06/06/clarification-adobe-terms-of-use'>blog.adobe.com/en/publish/2024/06/06/clarification-adobe-terms-of-use</a>}
        </div>
      </div>
    </>
}

export default Faq;