import '../../css/App.css'
import { loadStripe } from '@stripe/stripe-js';
import React, { useState, useEffect } from 'react';
import { useMediaQuery } from '@react-hook/media-query';
import Desktop from './Desktop/Desktop';
import Mobile from './Mobile/Mobile';
import Footer from '../Footer/Footer'
import Logo from '../../images/cheapcclogo.avif'

const stripePromise = loadStripe('pk_live_51Q0mpPAvc7OJE1HgrXR7hUfqHQT6vYzKOFhUyY5SOnGmCWzqyDNCNL4ZazSdmLlW2ypzidtPFySZ1P0CiLbCx4aK002N51ezBu');

function Bulk() {
  const [subscriptions, setSubscriptions] = useState([]);
  const [reviewsShown, setReviewsShown] = useState(false)
  const [newName, setNewName] = useState('');
  const [newEmail, setNewEmail] = useState('');
  const [newAutopay, setNewAutopay] = useState(false);
  const [newDuration, setNewDuration] = useState('N/A');
  const [faqShown, setFaqShown] = useState(false)
  const [currency, setCurrency] = useState('€')
  const [prices] = useState({ 30: 14, 365: 144, 730: 240, 1095: 320 });
  const [AdobePrices] = useState({ 30: 59.99, 365: 659.88, 730: 659.88 * 2, 1095: 659.88 * 3 });
  const [totalPrice, setTotalPrice] = useState(0)
  const [totalAdobePrice, setTotalAdobePrice] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [isPaymentDone, setIsPaymentDone] = useState(false);
  const [clientSecret, setClientSecret] = useState(null);
  const isMobile = useMediaQuery('(max-width: 668px)');

  const images = [
    "https://i.ibb.co/Tbt9wzx/Capture-d-e-cran-2024-08-05-a-15-49-46.png",
    "https://i.ibb.co/Yy74tBm/Capture-d-e-cran-2024-08-31-a-17-44-35.png",
    "https://i.ibb.co/CtH9wDR/Capture-d-e-cran-2024-08-31-a-17-45-07.png",
    "https://i.ibb.co/5G8Wm76/Capture-d-e-cran-2024-08-31-a-17-47-50.png",
    "https://i.ibb.co/VQNLn9v/Capture-d-e-cran-2024-08-31-a-17-48-04.png",
    "https://i.ibb.co/MGKkpfb/Capture-d-e-cran-2024-08-31-a-17-48-15.png",
    "https://i.ibb.co/Jmy2Fr1/Capture-d-e-cran-2024-08-31-a-17-49-35.png",
    "https://i.ibb.co/xChyrJV/Capture-d-e-cran-2024-09-12-a-13-58-44.png",
    "https://i.ibb.co/5B6k3Tb/Capture-d-e-cran-2024-09-12-a-14-03-35.png",
    "https://i.ibb.co/0XXGj74/Capture-d-e-cran-2024-09-19-a-18-33-12.png",
    "https://i.ibb.co/rMpyqxc/Capture-d-e-cran-2024-09-19-a-18-55-27.png",
    "https://i.ibb.co/fvm6N3C/Capture-d-e-cran-2024-09-20-a-23-04-19.png",
    "https://i.ibb.co/jw6qP12/Capture-d-e-cran-2024-10-11-a-18-13-11.png",
    "https://i.ibb.co/JBfhfsT/Capture-d-e-cran-2024-10-11-a-19-25-05.png",
    "https://i.ibb.co/NgqPWvC/Capture-d-e-cran-2024-10-13-a-16-16-40.png",
    "https://i.ibb.co/68RHwHm/Capture-d-e-cran-2024-10-13-a-16-30-02.png",
    "https://i.ibb.co/tQVswsd/Capture-d-e-cran-2024-10-13-a-16-35-26.png",
    "https://i.ibb.co/pnnRL29/Capture-d-e-cran-2024-10-13-a-16-36-11.png",
    "https://i.ibb.co/qR9rx4X/Capture-d-e-cran-2024-10-13-a-16-37-34.png",
    "https://i.ibb.co/znqR7qW/Capture-d-e-cran-2024-10-13-a-16-39-24.png",
    "https://i.ibb.co/TRPF5TX/Capture-d-e-cran-2024-10-13-a-16-41-17.png",
    "https://i.ibb.co/HPDcCf7/Capture-d-e-cran-2024-10-13-a-16-42-31.png",
    "https://i.ibb.co/5L4q0Y0/Capture-d-e-cran-2024-10-13-a-16-43-47.png",
    "https://i.ibb.co/ysRSFwb/Capture-d-e-cran-2024-10-13-a-17-52-56.png",
    "https://i.ibb.co/41BrsLz/Capture-d-e-cran-2024-10-14-a-19-17-36.png"
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://extreme-ip-lookup.com/json/?key=uxJZ5TPQRYbhGqTVc6DI');
        const data = await response.json();
        const country = data.countryCode;
        setCurrency(country === 'US' ? '$' : '€');
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [currency]);

  useEffect(() => {
    let total = 0;
    let totaladobe = 0
    subscriptions.map((subscription) => (
      total += prices[subscription.duration]
    ))
    subscriptions.map((subscription) => (
      totaladobe += AdobePrices[subscription.duration]
    ))

    setTotalPrice(total);
    setTotalAdobePrice(totaladobe)

  }, [subscriptions, AdobePrices, prices]);

  const handleAddSubscription = () => {
    setSubscriptions([
      ...subscriptions,
      { name: newName, email: newEmail, duration: newDuration, autopay: newAutopay },
    ]);
    setNewName('');
    setNewEmail('');
    setNewAutopay(false)
    setNewDuration('N/A');
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    try {

      const agent = navigator.userAgent;
      await fetch('/.netlify/functions/create-payment-intent', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          browserAgent: agent,
          amount: totalPrice,
          name: subscriptions[0].name,
          email: subscriptions[0].email,
          duration: 0,
          currency: currency,
          bulk: true,
          orders: subscriptions,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          setClientSecret(data.clientSecret);
        });

    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const appearance = {
    theme: 'stripe',
    variables: {
      colorPrimary: '#1c53de'
    }
  };

  const options = { clientSecret, appearance, business: "ASM" }

  return (
    <div className="App">
      <div className='logo-div'>
        <img style={{ display: 'flex', width: '100%', justifyContent: 'center' }} draggable={false} src={Logo} className='logo' alt='cheapcc-logo' />
      </div>
      <div className='main'>
      {(isMobile===false || isMobile==='false')
        && <Desktop handleSubmit={handleSubmit} clientSecret={clientSecret} subscriptions={subscriptions} setSubscriptions={setSubscriptions} newName={newName} setNewName={setNewName} newEmail={newEmail} setNewEmail={setNewEmail} newAutopay={newAutopay} setNewAutopay={setNewAutopay} newDuration={newDuration} setNewDuration={setNewDuration} handleAddSubscription={handleAddSubscription} currency={currency} totalPrice={totalPrice} totalAdobePrice={totalAdobePrice} isLoading={isLoading} isPaymentDone={isPaymentDone} stripePromise={stripePromise} options={options} setClientSecret={setClientSecret} prices={prices} setIsPaymentDone={setIsPaymentDone} reviewsShown={reviewsShown} setReviewsShown={setReviewsShown} images={images} faqShown={faqShown} setFaqShown={setFaqShown} />}
        {(isMobile==='true' || isMobile===true)
        && <Mobile />}
        <Footer />
      </div>
    </div>
  );
}

export default Bulk;
