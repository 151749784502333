import React from 'react';
import { Link } from 'react-router-dom';
import Footer from '../Footer/Footer';

function Refund() {

  return (
    <div className='refund-container' style={{ display:'grid', alignItems:'center', justifyContent:'center', gap:30 }}>
      <Link to='/' className='main-refund' style={{ flexDirection:'row', textDecoration:'none', justifyContent:'flex-start', gap:20, padding:20, width:'fit-content', minWidth:'fit-content' }}>
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="18.4961" height="14.8145">  <g>   <rect height="14.8145" opacity="0" width="18.4961" x="0" y="0"/>   <path d="M0 7.40234C0 7.64648 0.107422 7.87109 0.302734 8.05664L6.78711 14.5312C6.98242 14.7168 7.1875 14.8047 7.42188 14.8047C7.90039 14.8047 8.28125 14.4531 8.28125 13.9648C8.28125 13.7305 8.19336 13.4961 8.03711 13.3496L5.84961 11.123L1.98242 7.59766L1.77734 8.07617L4.92188 8.27148L17.2754 8.27148C17.7832 8.27148 18.1348 7.91016 18.1348 7.40234C18.1348 6.89453 17.7832 6.5332 17.2754 6.5332L4.92188 6.5332L1.77734 6.72852L1.98242 7.2168L5.84961 3.68164L8.03711 1.45508C8.19336 1.29883 8.28125 1.07422 8.28125 0.839844C8.28125 0.351562 7.90039 0 7.42188 0C7.1875 0 6.98242 0.078125 6.76758 0.292969L0.302734 6.74805C0.107422 6.93359 0 7.1582 0 7.40234Z" fill="black" />  </g> </svg>
      <h2 style={{ textDecoration:'none', color:'black' }}>
        Go back to home
      </h2>
      </Link>
      <div className='main-refund' style={{ marginTop:20 }}>
        <h1>Refund policy</h1>
        <p style={{ textAlign:'left', width:'fit-content', alignSelf:'flex-start' }}>You can get a 95% refund if you ordered your subscription less than 3 days before asking for a refund, without having to provide a valid reason.</p>
        <p style={{ textAlign:'left', width:'fit-content', alignSelf:'flex-start' }}>Our values are those of equality: everyone should be able to access the Adobe suite at an affordable price of no more than $15 a month.</p>
        <p style={{ textAlign:'left', width:'fit-content', alignSelf:'flex-start' }}>That's why, most of the time, refund requests are considered on a case-by-case basis, and we always strive to be honest and impartial in resolving issues.</p>
        <p style={{ textAlign:'left', width:'fit-content', alignSelf:'flex-start' }}>If you have an inquiry about anything, please contact us on Telegram (@cheapccnet) or by email (help@cheapcc.net).</p>
      </div>
      <Footer />
    </div>
  );
}

export default Refund;
