import React, { useState, useEffect } from "react";

function Slider({ imageUrls }) {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [showLargeImage, setShowLargeImage] = useState(false);
    const [loadingNext, setLoadingNext] = useState(false);
    const [loadingPrev, setLoadingPrev] = useState(false);
    const [preloadedImages, setPreloadedImages] = useState({});


    const preloadImage = (index) => {
        if (!preloadedImages[index] && imageUrls[index]) {
            const img = new Image();
            img.src = imageUrls[index];
            img.onload = () => {
                setPreloadedImages((prev) => ({ ...prev, [index]: true }));
            };
        }
    }

    useEffect(() => {
        preloadImage(currentImageIndex);
        preloadImage((currentImageIndex + 1) % imageUrls.length);
        preloadImage((currentImageIndex - 1 + imageUrls.length) % imageUrls.length);
    });

    const nextImage = () => {
        setLoadingNext(true);
        const nextIndex = (currentImageIndex + 1) % imageUrls.length;
        const img = new Image();
        img.src = imageUrls[nextIndex];
        img.onload = () => {
            setCurrentImageIndex(nextIndex);
            setLoadingNext(false);
        };
    };

    const prevImage = () => {
        setLoadingPrev(true);
        const prevIndex = (currentImageIndex - 1 + imageUrls.length) % imageUrls.length;
        const img = new Image();
        img.src = imageUrls[prevIndex];
        img.onload = () => {
            setCurrentImageIndex(prevIndex);
            setLoadingPrev(false);
        };
    };

    const openLargeImage = () => {
        setShowLargeImage(true);
    };

    const closeLargeImage = () => {
        setShowLargeImage(false);
    };

    return (
        <div className="relative">
            {!showLargeImage && (
                <div style={{ marginTop: 30, display: 'flex', flexDirection: 'column', gap: 30 }}>
                    <img
                        src={imageUrls[currentImageIndex]}
                        style={{ height: 'auto', width: '80%', alignSelf:'center', boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.5)', padding: 0 }}
                        className="w-full h-full bg-cover bg-center"
                        alt="thumbnail"
                        onClick={openLargeImage}
                    />
                    <div style={{ gap: 10, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                        <button
                            onClick={prevImage}
                            style={{ outline: 'none', width: 50, height: 30 }}
                            disabled={loadingPrev}
                        >
                            {loadingPrev ? <i className="fas fa-spinner fa-spin text-white text-2xl"></i> : <i className="fas fa-chevron-left text-white text-2xl"></i>}
                        </button>
                        <button
                            onClick={nextImage}
                            style={{ outline: 'none', width: 50, height: 30 }}
                            disabled={loadingNext}
                        >
                            {loadingNext ? <i className="fas fa-spinner fa-spin text-white text-2xl"></i> : <i className="fas fa-chevron-right text-white text-2xl"></i>}
                        </button>

                    </div>
                </div>
            )}

            {showLargeImage && (
                <div
                    style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100vh', backgroundColor: 'rgba(0,0,0,0.7)', zIndex: 999, backdropFilter:'blur(10px)', boxShadow: '0px 0px 100px 30px rgba(0,0,0,1)' }}
                    onClick={closeLargeImage}
                >
                    <img
                        src={imageUrls[currentImageIndex]}
                        alt={`Screenshot ${currentImageIndex + 1}`}
                        style={{ mawWidth: 'calc(100% - 100px)', maxHeight: 'calc(100vh - 100px)', marginTop: 100 }}
                    />
                </div>
            )}
        </div>
    );
}

export default Slider