import { Elements } from "@stripe/react-stripe-js";
import Wrapper from "../../Wrapper/Wrapper";

const Payment = ({ clientSecret, formSubmittedAutoPay, name, duration, email, isAutoPay, currency, prices, isLoading, isPaymentDone, stripePromise, options, setClientSecret, priceData, setIsPaymentDone, setFormSubmittedAutoPay }) => {
    return <>
    <div className={`payment-container ${(isPaymentDone === false && clientSecret) || (formSubmittedAutoPay === true && isPaymentDone === false) ? "shown" : ''}`}>
          {(isPaymentDone === false && clientSecret && isAutoPay === false) && <Elements stripe={stripePromise} options={options}>
            <button onClick={() => (setClientSecret(null))} className='return-button'><span style={{ transform: 'translateY(-1px)', fontSize: 16 }}>←</span>Return to home</button>
            <Wrapper currency={currency} priceId={priceData[duration]} autoPay={isAutoPay} name={name} email={email} duration={duration} prices={prices} isPaymentDone={isPaymentDone} setIsPaymentDone={setIsPaymentDone} clientSecret={clientSecret} bulk={false} bulk_data={null} />
          </Elements>}
          {(isPaymentDone === false && isAutoPay === true && formSubmittedAutoPay === true) && <Elements stripe={stripePromise} options={options}>
            <button onClick={() => (setFormSubmittedAutoPay(false))} className='return-button'><span style={{ transform: 'translateY(-1px)', fontSize: 16 }}>←</span>Return to home</button>
            <Wrapper currency={currency} priceId={priceData[duration]} autoPay={isAutoPay} name={name} email={email} duration={duration} prices={prices} isPaymentDone={isPaymentDone} setIsPaymentDone={setIsPaymentDone} bulk={false} bulk_data={null} />
          </Elements>}
        </div>
        <div className={`payment-container-done ${isPaymentDone === true && isLoading === false ? "shown" : ''}`}><div className='success-div'><div class="animation-ctn">
          <div class="icon icon--order-success svg">
            <svg xmlns="http://www.w3.org/2000/svg" width="154px" height="154px">
              <g fill="none" stroke="#22AE73" stroke-width="2">
                <circle cx="77" cy="77" r="72" style={{ strokeDasharray: '480px, 480px', strokeDashOffset: 960 }}></circle>
                <circle id="colored" fill="#22AE73" cx="77" cy="77" r="72" style={{ strokeDasharray: '480px, 480px', strokeDashOffset: 960 }}></circle>
                <polyline class="st0" stroke="#fff" stroke-width="10" points="43.5,77.8 63.7,97.9 112.2,49.4 " style={{ strokeDasharray: '100px, 100px', strokeDashOffset: 200 }} />
              </g>
            </svg>
          </div>
        </div>{isPaymentDone === true && isLoading === false && <h2 style={{ textAlign: 'center', marginTop: 60 }}>Payment successful.</h2>}{isPaymentDone === true && isLoading === false && <p style={{ textAlign: 'center', marginTop: 60 }}>You should receive your invitation email within 24 hours.</p>}{isPaymentDone === true && isLoading === false && <p style={{ textAlign: 'center', marginTop: 10 }}>If any problem, contact us by email:</p>}{isPaymentDone === true && isLoading === false && <h3 style={{ textAlign: 'center', marginTop: 20 }}>help@cheapcc.net</h3>}</div></div>
    </>
}

export default Payment;